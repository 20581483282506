import type { JSONDocNode } from '@atlaskit/editor-json-transformer';

import type { ReadTimeQuery as ReadTimeQueryResponse } from './__types__/ReadTimeQuery';
import { extractContentFeatures } from './featuresExtractor';

const wpm = 265; // words per minute, inspired by Medium.com
const minute = 60;

const calculateImageReadTime = (imageCount: number): number => {
	let readTime: number = 0;
	for (let i = 0; i < imageCount; i++) {
		readTime += Math.max(12 - i, 3);
	}
	return readTime;
};

// Calculate Read time based on:
// 1. Average reading speed 265 WPM
// 2. Image count (1 sec added for each image until first 10 images post that 3 sec for each subsequent image)
export const calculateEstimatedReadTime = (adf?: JSONDocNode | null): number => {
	if (adf) {
		const features = extractContentFeatures(adf);
		const readTime =
			((features.wordCount / wpm) * 60 + calculateImageReadTime(features.imageCount)) / minute;
		if (readTime > 0.5) {
			return Math.ceil(readTime);
		}
	}
	return 0;
};

export const getAdf = (response: ReadTimeQueryResponse): JSONDocNode | null => {
	const representation = response?.content?.nodes?.[0]?.body?.dynamic?.representation;
	if (representation === 'atlas_doc_format') {
		const adfString = response?.content?.nodes?.[0]?.body?.dynamic?.value;
		if (adfString) {
			try {
				return JSON.parse(adfString);
			} catch {
				return null;
			}
		}
	}
	return null;
};

export const getContentFeatureExtractedEventObject = ({
	contentType,
	contentId,
	adf,
	isInEditor,
}: {
	contentType: string;
	contentId: string;
	adf: JSONDocNode;
	isInEditor: boolean;
}) => ({
	type: 'sendOperationalEvent',
	data: {
		source: 'confluence-frontend',
		action: 'extracted',
		actionSubject: 'contentFeature',
		objectType: contentType,
		objectId: contentId,
		attributes: {
			...extractContentFeatures(adf),
			isInEditor,
		},
	},
});

export const getWordCount = (adf?: JSONDocNode | null) =>
	adf ? extractContentFeatures(adf).wordCount : 0;
