import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ErrorDisplay } from '@confluence/error-boundary';

import { ReadTimeComponent } from './ReadTimeComponent';
import { ReadTimeQuery } from './ReadTimeQuery.graphql';
import {
	calculateEstimatedReadTime,
	getAdf,
	getContentFeatureExtractedEventObject,
	getWordCount,
} from './readTimeUtils';

export const OriginalReadTime = ({
	contentId,
	isAbbreviatedReadTime,
	isDetailsPanel,
	showWordCount,
}: {
	contentId: string;
	isAbbreviatedReadTime?: boolean;
	isDetailsPanel?: boolean;
	showWordCount?: boolean;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { data, loading, error } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ReadTimeQuery,
		{
			variables: { contentId },
			fetchPolicy: 'cache-only',
		},
	);

	useEffect(() => {
		const adf = getAdf(data);
		if (!createAnalyticsEvent || !adf || loading || error || !contentId) {
			return;
		}
		const contentType = data?.content?.nodes?.[0]?.type;
		createAnalyticsEvent(
			getContentFeatureExtractedEventObject({ contentType, contentId, adf, isInEditor: false }),
		).fire();
	}, [contentId, loading, data, error, createAnalyticsEvent]);

	const { readTime, wordCount } = useMemo(() => {
		const adf = getAdf(data);
		if (!adf || loading || error || !contentId) {
			return { readTime: null, wordCount: null };
		}
		return { readTime: calculateEstimatedReadTime(adf), wordCount: getWordCount(adf) };
	}, [loading, error, data, contentId]);

	if (error) {
		return <ErrorDisplay error={error} />;
	}

	if (readTime || (showWordCount && wordCount)) {
		return (
			<ReadTimeComponent
				readTime={readTime}
				wordCount={wordCount}
				pageId={contentId}
				isAbbreviatedReadTime={isAbbreviatedReadTime}
				isDetailsPanel={isDetailsPanel}
				showWordCount={showWordCount}
			/>
		);
	}

	return null;
};
