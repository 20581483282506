import type { ComponentType } from 'react';
import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';

import type { EditorView } from '@atlaskit/editor-prosemirror/view';

type SubscriptionCallback = (editorView: EditorView) => void;
export type SubscriptionHandlerFnType = (callback: SubscriptionCallback) => void;
type DocChangeContextType = {
	subscribeDocChange: SubscriptionHandlerFnType;
	unsubscribeDocChange: SubscriptionHandlerFnType;
};
export type WithDocChangeContextProps = {
	handleDocChange: (editorView: EditorView) => void;
};

const DocChangeContext = createContext<DocChangeContextType>({
	subscribeDocChange: () => {},
	unsubscribeDocChange: () => {},
});

const DocChangeContextProvider = ({
	children,
}: {
	children: (props: WithDocChangeContextProps) => React.ReactElement;
}) => {
	const subscriptions = useRef<Set<SubscriptionCallback>>(new Set());

	const handleDocChange = useCallback((editorView: EditorView) => {
		subscriptions.current.forEach((callback) => {
			callback(editorView);
		});
	}, []);

	const subscribeDocChange = useCallback((callback: SubscriptionCallback) => {
		subscriptions.current.add(callback);
	}, []);

	const unsubscribeDocChange = useCallback((callback: SubscriptionCallback) => {
		subscriptions.current.delete(callback);
	}, []);

	const value = useMemo(
		() => ({
			subscribeDocChange,
			unsubscribeDocChange,
		}),
		[subscribeDocChange, unsubscribeDocChange],
	);

	return (
		<DocChangeContext.Provider value={value}>
			{children({ handleDocChange })}
		</DocChangeContext.Provider>
	);
};

export const useDocChangeContext = () => useContext(DocChangeContext);

export function withDocChangeContext<T>(
	WrappedComponent: ComponentType<T & WithDocChangeContextProps>,
) {
	return (props: T) => (
		<DocChangeContextProvider>
			{({ handleDocChange }) => <WrappedComponent {...props} handleDocChange={handleDocChange} />}
		</DocChangeContextProvider>
	);
}
